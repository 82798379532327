.corrector-body{
    background-image: url(../../assets/img/bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    min-height: 100vh;
    padding-top: 80px;
}