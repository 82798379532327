/* CorrectorModal.css */
.corrector-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.corrector-modal {
  background: #fff;
  border-radius: 16px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.corrector-modal-header {
  background-color: #ff7f0e; /* même couleur que le chat-header */
  color: #fff;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.corrector-modal-header h2 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
}

/* Bouton Close identique au bouton Submit Revision */
.corrector-modal-close {
  background-color: #ff7f0e;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.corrector-modal-close:hover {
  background-color: #e66a00;
}

.corrector-modal-body {
  padding: 16px;
  font-family: sans-serif;
  color: #333;
}

.corrector-modal-footer {
  padding: 16px;
  text-align: right;
  background-color: #fafafa;
}

/* Bouton Close dans le footer, identique au bouton Submit Revision */
.corrector-modal-footer button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #ff7f0e;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .corrector-modal-footer button:hover {
    background-color: #e66a00;
  }