/*
.ant-upload.ant-upload-select{
    position: relative;
    width: 200px!important;
    height: 200px!important;
    border: 1.26px solid #3E9DB2!important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ddd!important;
}
    */
.ant-upload-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.up-cont{
    width: 240px;
    display: flex;
    min-height: 150px;
    justify-content: center;
    margin-top: -1.5rem;
}

