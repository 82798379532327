.web_target {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

.chat-container {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: block;
  flex-direction: column;
  position: fixed;
  right: 5vh;
  bottom: 5vh;
}

.chat-header {
  background-color: #ff7f0e; /* Weebseat's orange-like color */
  color: #fff;
  padding: 16px;
  display: flex;
  align-items: center;
}

.chat-header .logo {
  width: 32px;
  height: 32px;
  background-color: #fff;
  color: #ff7f0e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 12px;
}

.chat-header h2 {
  font-size: 1.1rem;
  font-weight: 600;
}

.chat-content {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.intro p {
  line-height: 1.5;
  margin-bottom: 8px;
}

.choices {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.choice {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.2s;
}

.choice:hover {
  background-color: #f0f0f0;
}

.icon {
  font-size: 1.2rem;
}

/* Zone de révision avec transition smooth */
.improvement-box {
  position: relative; /* Pour positionner le tooltip en interne */
  margin-top: 8px;
  border: 1px solid #ddd;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 10px;
  
  /* Transition pour déplier/replier */
  max-height: 0;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.improvement-box.open {
  max-height: 500px; /* Ajuster cette valeur selon le contenu */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

/* Styles pour la textarea et le bouton de soumission */
.improvement-box textarea {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: sans-serif;
  resize: none;
}

.improvement-box button {
  margin-top: 8px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #ff7f0e;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s;
}

.improvement-box button:hover {
  background-color: #e66a00;
}

/* Tooltip pour l'aide */
.tooltip-container {
  position: absolute;
  bottom: 8px;
  right: 10px;
  z-index: 1001;
  cursor: pointer;
}

.tooltip-icon {
  background-color: #ff7f0e;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
}

.tooltip-text {
  visibility: hidden;
  width: 220px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  bottom: 50px;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Loader overlay styles */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  display: flex;
  flex-direction: column;
}

/* Simple spinner */
.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #db8d34;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.char-counter {
  font-weight: 100;
  font-size: small;
  text-align: end;
}

/* Texte sous le loader */
.loading-text {
  margin-top: 10px;
  font-size: 1rem;
  color: #db8d34;
  text-align: center;
  font-weight: 500;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* --------------------------- */
/* Media Queries Responsive    */
/* --------------------------- */

/* Tablettes (max-width: 1024px) */
@media only screen and (max-width: 1024px) {
  .chat-container {
    max-width: 90%;
    right: 5%;
    bottom: 5%;
  }
  .chat-header h2 {
    font-size: 1rem;
  }
  .tooltip-icon {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
  .tooltip-text {
    width: 200px;
  }
  .improvement-box textarea {
    font-size: 14px;
  }
  .improvement-box button {
    font-size: 14px;
    padding: 6px 10px;
  }
  
}

/* Téléphones (max-width: 600px) */
@media only screen and (max-width: 600px) {
  .chat-container {
    max-width: 98%;
    width: 98%;
  
    right: 1%;
    bottom: 1%;
  }
  .chat-header {
    padding: 12px;
  }
  .chat-header .logo {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
  .chat-header h2 {
    font-size: 1rem;
  }
  .intro p:nth-of-type(2) {
    display: none;
  }
  .chat-content {
    padding: 12px;
  }
  .choice {
    padding: 8px;
  }
  .improvement-box {
    border-radius: 0;
    padding: 8px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .improvement-box textarea {
    padding: 6px;
    font-size: 14px;
  }
  .improvement-box button {
    padding: 6px 10px;
    font-size: 14px;
  }
  .tooltip-icon {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .tooltip-text {
    width: 180px;
    bottom: 45px;
  }
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #db8d34;
  }
}
